import React from "react"
import { Badge, chakra } from "@chakra-ui/react"

// eslint-disable-next-line no-restricted-globals
const isNumber = (n) => typeof n === "number" && isFinite(n)

const Discount = ({ price, startPrice, ...rest }) => {
  if (!isNumber(price) || !isNumber(startPrice)) {
    return null
  }
  const discount = Math.round(100 - (price * 100) / startPrice)
  if (discount === 0) {
    return null
  }

  return <Badge {...rest}>{`-${discount}%`}</Badge>
}

export default chakra(Discount)
