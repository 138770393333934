import React from "react"
import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  Badge,
  useBreakpointValue,
} from "@chakra-ui/react"
import useEmblaCarousel from "embla-carousel-react"
import { BiHeart } from "react-icons/bi"

import Discount from "./Discount"
import { Cabin, DropPoint, Passenger } from "./Icons"
import { PriceTag } from "./PriceTag"
import { Offer } from "../lib/types/offer"
import { mainImage } from "../domain/boat"
import "../css/embla.css"
import { trackEvent } from "../domain/track"
import { PrevButton, NextButton, Dot } from "./Embla"

interface Props {
  offer: Offer
}

export const BoatBox = ({ offer }: Props) => {
  const { boat } = offer
  const isMobile = useBreakpointValue({ base: true, sm: false })

  const [emblaRef, embla] = useEmblaCarousel({ draggable: isMobile, loop: true })
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([])

  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [loadedAll, setLoadedAll] = React.useState(false)

  const onSelect = React.useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  const scrollPrev = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollPrev()
    },
    [embla]
  )

  const scrollNext = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (embla) embla.scrollNext()
      if (embla?.selectedScrollSnap() === boat.images.length) {
        setLoadedAll(true)
      }
    },
    [embla, boat.images.length]
  )

  React.useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
  }, [embla, setScrollSnaps, onSelect])

  const handleSaveBoat = () => {
    trackEvent({ category: "Fake", action: "clicked", label: "Save my boat" })
  }

  return (
    <Box
      pos="relative"
      overflow="hidden"
      outline="none"
      sx={{
        cursor: "pointer",
        color: "text",
        fontWeight: "bold",
        _hover: {
          button: { display: "flex" },
        },
      }}
    >
      <Box className="embla">
        <a className="embla__viewport" ref={emblaRef} href={`/boats/${boat.uid}?offerId=${offer.id}`}>
          <div className="embla__container">
            <chakra.img
              className="embla__slide"
              src={mainImage(boat)}
              alt={boat.modelName}
              sx={{
                objectFit: "cover",
                width: "100%",
                height: { base: "200px", lg: "300px" },
                ":hover": {
                  opacity: ".85",
                  transition: "opacity .2s ease-out",
                },
              }}
            />
            {boat.images
              .filter((i) => i.cardImageSrc !== mainImage(boat).cardImageSrc)
              .slice(0, 19)
              .map((image, i) => (
                <chakra.img
                  className="embla__slide"
                  key={image.cardImageSrc}
                  src={loadedAll ? image.cardImageSrc : selectedIndex >= i ? image.cardImageSrc : ""}
                  alt={boat.modelName}
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: { base: "200px", lg: "300px" },
                    ":hover": {
                      opacity: ".85",
                      transition: "opacity .2s ease-out",
                    },
                  }}
                />
              ))}
          </div>
        </a>

        <PrevButton onClick={scrollPrev} />
        <NextButton onClick={scrollNext} />
        {scrollSnaps.length <= 20 && (
          <Center z-index="20" width="100%" position="absolute" bottom="10px" pt="10px">
            {scrollSnaps.map((_, index) => (
              <Dot key={index} selected={index === selectedIndex} />
            ))}
          </Center>
        )}
      </Box>
      <Center pos="absolute" rounded="full" top={4} right={4} bg="rgba(255,255,255,0.5)" _hover={{ bg: "white" }}>
        <Popover>
          <Tooltip label="Save to favourites">
            <Box>
              <PopoverTrigger>
                <Button
                  onClick={handleSaveBoat}
                  size="sm"
                  alignItems="center"
                  justifyContent="center"
                  variant="unstyled"
                >
                  <Box as={BiHeart} boxSize="20px" mx="auto" fill="primary" />
                </Button>
              </PopoverTrigger>
            </Box>
          </Tooltip>
          <Portal>
            <PopoverContent borderRadius={0}>
              <PopoverCloseButton />
              <PopoverHeader fontSize="0.9rem" fontWeight={700}>
                Sorry, this feature is not quite ready.
              </PopoverHeader>
              <PopoverBody p={4}>
                <Text>
                  If you need help finding a perfect boat, please e-mail us at{" "}
                  <a href="mailto:contact@highsails.com">contact@highsails.com</a> or WhatsApp us on +44 20 8187 1871
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Center>

      <a href={`/boats/${boat.uid}?offerId=offerId=${offer.id}`}>
        <Flex align="center" my={1}>
          <chakra.span sx={{ fontWeight: "bold" }}>{`${boat.modelName}, ${boat.year} `}</chakra.span>
          <Tooltip label="Boat capacity">
            <Box>
              <Passenger color="primary" w={5} h={5} ml={3} />
            </Box>
          </Tooltip>
          <chakra.span sx={{ ml: 1 }}>{boat.passengers}</chakra.span>
          <Tooltip label="Number of cabins">
            <Box>
              <Cabin color="primary" w={5} h={5} ml={3} />
            </Box>
          </Tooltip>
          <chakra.span sx={{ ml: 1 }}>{boat.cabins}</chakra.span>
        </Flex>
        <Box>
          <PriceTag currency={offer.currency} startPrice={offer.startPrice} price={offer.discountedPrice} />
          <Discount sx={{ ml: 3 }} price={offer.discountedPrice} startPrice={offer.startPrice} />

          {offer.boat.company.extras.map((extra) => (
            <Badge key={extra} ml={3} bg="black">
              {extra}
            </Badge>
          ))}
        </Box>
        <Flex align="center" justify="space-between">
          <Flex color="muted" align="center" fontWeight="normal">
            <DropPoint sx={{ fill: "muted", position: "relative", left: "-4px" }} />
            {offer.startBase.city}
          </Flex>
          {/* TODO: ratings */}
          {/* <Text>4/5</Text> */}
        </Flex>
      </a>
    </Box>
  )
}
