import * as React from "react"

import { Box, Center, Heading, SimpleGrid } from "@chakra-ui/layout"

import { BoatBox } from "./BoatBox"
import { Offer } from "../lib/types/offer"
import Link from "./Link"
import { searchParamstoQueryString } from "src/lib/helpers/search-params"

interface Params {
  sailingArea?: string
  guests?: string
  boatType?: string
  maxPrice?: string
}

interface Props {
  offers: Offer[]
  count?: number
  searchParams?: Params
}

export const AdviceResultsRecommendedBoats = (props: Props) => {
  const count = props.count
  const offers = props.offers as Offer[] | undefined
  const qs = props.searchParams ? searchParamstoQueryString(props.searchParams as Params) : ""
  return (
    <Box py={16} px={{ base: 4, xl: 20 }} bg="gray.50">
      <Heading mt={[16, 0]} mb={10} fontSize="32px" as="h2" fontWeight="normal" color="primary" textAlign="center">
        Recommended Boats
      </Heading>

      {count === 0 || !offers ? (
        <Center textAlign="center" color="muted">
          We're sorry we couldn't find any offers to fit your search preferences.
          <br />
          Please change your search criteria and try again.
        </Center>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          {offers?.slice(0, 3).map((offer) => (
            <BoatBox key={offer.id} offer={offer} />
          ))}
        </SimpleGrid>
      )}
      <Center mt={10}>
        <Link fontWeight="semibold" fontSize="1.1rem" color="primary" to={"/search?" + qs}>
          See All Boats
        </Link>
      </Center>
    </Box>
  )
}
