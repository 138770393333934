import * as React from "react"
import { Box, Flex, Heading, HStack, SimpleGrid, Stack, Text } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"
import { IoMdBoat } from "react-icons/io"
import { BiEuro } from "react-icons/bi"
import { RichText } from "prismic-reactjs"
import { Location } from "src/lib/types/location"

interface Props {
  sailingArea: Location
  country: Location
  stats: {
    count: number
    startPrice: number
  }
}

export const AdviceResultsSailingArea = (props: Props) => {
  const location = `${props.country.data.name} - ${props.sailingArea.data.name}`

  return (
    <Stack bg="primary" color="white" py={14} px={{ base: 4, md: 20, lg: 60 }} spacing={8}>
      <HStack w="100%" justifyContent="space-evenly" pb={8} borderBottomWidth={1}>
        <Flex alignItems="center">
          <Box border="1px solid white" borderRadius={100} p={3} mr={4}>
            <IoMdBoat size={25} />
          </Box>
          <Text fontSize={15} lineHeight="20px">
            <Text as="span" color="blue.200">
              {props.stats.count}
            </Text>{" "}
            Boats
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Box border="1px solid white" borderRadius={100} p={3} mr={4}>
            <BiEuro size={25} />
          </Box>
          <Text fontSize={15} lineHeight="20px">
            from{" "}
            <Text as="span" color="blue.200">
              €{props.stats.startPrice}
            </Text>
          </Text>
        </Flex>
      </HStack>

      <SimpleGrid columns={{ base: 1, lg: 2 }} pt={8} spacing={8}>
        <Box>
          <Image src={props.sailingArea.data.preview_image.url} w="100%" h={{ base: 300, md: 400 }} objectFit="cover" />
        </Box>
        <Stack spacing={6}>
          <Heading fontSize="40px" as="h3" fontWeight="normal">
            {location}
          </Heading>

          <Box fontSize={14} lineHeight="20px">
            <RichText render={props.sailingArea.data.summary} />
          </Box>
          <Box>
            <Text fontWeight="bold">Top thing to do</Text>
            <Box fontSize={14} lineHeight="20px">
              <RichText render={props.sailingArea.data.things_to_do} />
            </Box>
          </Box>
          <Box>
            <Text fontWeight="bold">Top place to visit</Text>
            <Box fontSize={14} lineHeight="20px">
              <RichText render={props.sailingArea.data.place_to_visit} />
            </Box>
          </Box>
        </Stack>
      </SimpleGrid>
    </Stack>
  )
}
