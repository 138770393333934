import * as React from "react"
import { Box, Text } from "@chakra-ui/layout"
import { useLocation } from "@reach/router"
import { useQuery } from "react-query"
import { Center, Heading, Image, Spinner } from "@chakra-ui/react"

import { AdviceResultsSailingArea } from "../../components/AdviceResultsCountry"
import { AdviceResultsRecommendedBoats } from "../../components/AdviceResultsRecommendedBoats"
import { AdviceBoatType } from "../../components/AdviceBoatType"
import { fetchLocation } from "src/client/lambdas"
import Footer from "src/components/Footer"
import { Location } from "src/lib/types/location"
import { OffersResponse } from "src/lib/types/offer"
import axios from "axios"

interface Params {
  sailingArea?: string
  passengers?: string
  boatType?: string
  maxPrice?: string
}

interface LocationResults {
  location: Location
  country: Location
}

export default function Results() {
  const location = useLocation()
  const params: Params = Object.fromEntries(new URLSearchParams(location.search) as any)
  const uid = params.sailingArea
  const { data, isLoading } = useQuery<LocationResults>(["location", params], () => fetchLocation(uid || ""), {
    enabled: !!uid,
  })

  const sailingArea = data?.location
  const country = data?.country

  const searchParams = {
    boatType: params.boatType,
    destinationSailingAreas: sailingArea?.data.destination_code,
    maxPrice: params.maxPrice || undefined,
    passengers: params.passengers || undefined,
  }

  const fetchOffers = () => axios.get<OffersResponse>("/api/offers-search", { params: { ...searchParams } })

  const { data: response, isLoading: boatsLoading } = useQuery(
    ["results-recommended-boats", searchParams],
    fetchOffers,
    { staleTime: 6 * 60 * 60 * 1000 }
  )

  // TODO: this only accounts for returned offers after pagination, need the lowest from all of them
  const startPrice = React.useMemo(
    () =>
      response?.data.offers.reduce((acc, i) => {
        if (acc === 0 || i.discountedPrice < acc) {
          acc = i.discountedPrice
        }
        return acc
      }, 0) || 0,
    [response?.data.offers]
  )

  return (
    <>
      {isLoading || boatsLoading ? (
        <Center h="100vh" w="100vw">
          <Spinner />
        </Center>
      ) : !sailingArea || !country ? (
        <Center>
          <Text>Sorry, we couldn't find that sailing area</Text>
        </Center>
      ) : (
        <>
          <Image
            src={sailingArea.data.hero_image.url}
            width="100%"
            height={["197px", "560px", "700px"]}
            pos="absolute"
          />

          <Center bg="rgba(0,0,5,0.5)" position="relative" h={["197px", "560px", "700px"]}>
            <Heading
              mt={[16, 0]}
              fontSize={{ base: "2.7rem", md: "4.5rem" }}
              as="h1"
              fontWeight="normal"
              color="white"
              textAlign="center"
            >
              Here is our recommendation
            </Heading>
          </Center>
          <Box>
            <AdviceResultsSailingArea
              country={country}
              sailingArea={sailingArea}
              stats={{ count: response?.data.count || 0, startPrice }}
            />
            {response?.data && (
              <AdviceResultsRecommendedBoats
                offers={response.data.offers}
                count={response.data.count}
                searchParams={searchParams}
              />
            )}
            <AdviceBoatType />
          </Box>
        </>
      )}
      <Footer />
    </>
  )
}
