export function datalayerPush(data: any) {
  const isProd = process.env.NODE_ENV === "production"

  if (typeof window !== "undefined" && (window as any).dataLayer) {
    isProd ? (window as any).dataLayer.push(data) : console.log("DATA LAYER DEBUG:", data)
  }
}

interface TrackProps {
  category: string
  action: string
  label: string
}

export const trackEvent = ({ category, action, label }: TrackProps) => {
  try {
    datalayerPush({
      event: "gaEvent",
      gaEventCategory: category,
      gaEventAction: action,
      gaEventLabel: label,
    })
  } catch {
    // do nothing
  }
}

export const trackSearch = (data: any) => {
  const cleanedData = { ...data }

  if (cleanedData.email) {
    delete cleanedData.email
  }

  delete cleanedData.destination

  for (const [key, value] of Object.entries(cleanedData)) {
    trackEvent({
      category: "Search",
      action: "searchExecuted",
      label: `${key}: ${value}`,
    })
  }
}
