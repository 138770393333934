import * as React from "react"
import { Box, Heading, SimpleGrid, Stack } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"
import { useQuery } from "react-query"
import { useLocation } from "@reach/router"
import { RichText } from "prismic-reactjs"
import { fetchBoatType } from "src/client/lambdas"

export const AdviceBoatType = () => {
  const location = useLocation()
  const params = Object.fromEntries(new URLSearchParams(location.search) as any)
  const uid = params.boatType
  const { data, isLoading } = useQuery(["boatType", uid], () => fetchBoatType(uid))

  if (isLoading || !uid || !data) return null
  return (
    <Box py={16} px={{ base: 4, md: 20, lg: 60 }}>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
        <Image
          src={data.data.image.url}
          w="100%"
          h={{ base: 200, md: 300 }}
          objectFit="cover"
          objectPosition="bottom"
        />
        <Stack>
          <Heading as="h3" fontSize={32} fontWeight="normal" color="primary">
            {data.data.name}
          </Heading>
          <Box fontSize={14} lineHeight="20px">
            <RichText render={data.data.description} />
          </Box>
        </Stack>
      </SimpleGrid>
    </Box>
  )
}
